@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

.app {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 2px;
  background-color: #fffbfb;
  font-size: 18px;
}

nav ul {
  list-style: none;
  display: flex;
  /*background-color: #afabab;*/
  margin-bottom: 0px;
}

nav ul li {
  /*padding: 10px; I am safe to remove this, It only effects the nav menu*/
}

nav ul li a {
  color: #131c2a;
  text-decoration: none;
}

.current {
  border-bottom: 2px solid #162133;
}

h1 {
  margin-bottom: px;
  color: black;
}

p {
  margin-bottom: 5px;
}

a {
  text-decoration: none;
}

label {
  display: block;
  text-align: right;
}

fieldset {
  border: 0;
}

textarea {
  padding: 5px 5px;
}

input, textarea {
  border: 1px solid black;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.light-text {
  /*color: #dcd9d9; The colors are now purposely inverted, please leave this text color as dark unless refactoring all that call this class*/
  color: #162133;
}

.dark-text {
  color: #162133;
}

.blue-text {
  color: rgb(12, 99, 199);
}

.green-text {
  color: rgb(87, 148, 87);
}

.orange-text {
  color: rgb(199, 128, 23);
}

.red-text {
  color: rgb(197, 74, 74);
}

.underline {
  text-decoration: underline;
}

.bg-dark-green {
  background-color: rgb(36, 83, 36) !important;
}

.bg-green {
  background-color: rgb(87, 148, 87) !important;
}

.bg-light-green {
  background-color: rgb(143, 204, 143) !important;
}

.bg-purple {
  background-color: rgb(131, 61, 130) !important;
}

.bg-yellow {
  background-color: rgb(232, 228, 27) !important;
}

.bg-orange {
  background-color: rgb(199, 128, 23) !important;
}

.bg-red {
  background-color: rgb(197, 74, 74) !important;
}

.bg-blue {
  background-color: rgb(166, 200, 239) !important;
}

.bg-darkest {
  background-color: #131c2a !important;
}

.bg-dark {
  background-color: #162133 !important;
}

.bg-lightest {
  background-color: #dcd9d9 !important;
}

.bg-light {
  background-color: #b9b6b6 !important;
}

.bg-white {
  background-color: white;
}

.rounded {
  border-radius: 5px !important;
}

.container {
  max-width: 100%;
  padding: 5px 5px;
  display: flex;
}

.contained {
  padding: 0px 10px;
  flex: 1;
}

.scroll-wrapper {
  overflow-x: auto; /* Enables horizontal scrolling */
  width: 100%; /* Constrains the container to the width of the viewport */
  box-sizing: border-box;
}

.scroll-container {
  display: flex; /* Flexbox layout */
  white-space: nowrap; /* Prevent wrapping to new lines */
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
}

.container-2 {
  padding: 5px 5px;
  display: flex; /* Flexbox layout for inner buttons and content */
}

.contained-2 {
  padding: 0px 10px;
  flex: 0 0 auto; /* Prevents stretching */
  max-width: 140px; /* Ensures max-width for each item */
}

.contained-smaller {
  padding: 0px 10px;
  flex: .6;
}

.list-view {
  background-color: #dcd9d9;
  border-radius: 5px;
}

.header {
  background-color: #b9b6b6;
  border-radius: 2px;
}

.pr-100 {
  padding-right: 100%;
}

.btn {
  /*background-color: #b9b6b6; This is the old button color*/
  background-color: #162133;
  border: 2px;
  border-radius: 5px;
  border-color: black;
  cursor: pointer;
  padding: 8px 8px;
  margin: 2px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  display: inline-block;
  flex: 1;
  color: white;
}

.search-bar {
  width: 100%;
  border: 1px solid black;
}

.modal-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transform: translateZ(0);
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2.5em 1.5em 1.5em 1.5em;
  background-color: #ffffff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (min-width: 500px) {
  /* Center the Modal! */
  .modal-area {
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    max-width: 80em;
    max-height: calc(100% - 1em);
  }
}

._modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  line-height: 1;
  background: #f6f6f7;
  border: 0;
  box-shadow: 0;
  cursor: pointer;
}

._modal-close-icon {
  width: 25px;
  height: 25px;
  fill: transparent;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 2;
}

.modal-body {
  padding-top: 0.25em;
}
._hide-visual {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.scroll-lock {
  overflow: hidden;
  margin-right: 17px;
}

.large-checkbox{
  transform:scale(2);
}

.vertically-aligned{
  display: flex;
	flex-direction: row;
	align-items: center;
}
